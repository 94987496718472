<template>
  <div class="login-logo">
    <div class="logo_pic">
      <img src="~assets/img/login/logo.jpeg" />
    </div>
    <div class="name">
      门店指挥官
    </div>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.login-logo {
  display: flex;
  margin-bottom: 24px;
  .logo_pic{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    img{
      width: 50px;
      height: 50px;
    }
  }
  i {
    font-size: 36px;
  }
  .name {
    margin-left: 10px;
    color: rgba(80, 80, 80, 1);
    font-size: 16px;
    display: flex;
    align-items: center;
  }
}
</style>




