<template>
  <div class="login-footer">
    <p>长桑健康管理咨询（上海）有限公司</p>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.login-footer {
  position: absolute;
  bottom: 40px;
  color: rgba(80, 80, 80, 1);
  font-size: 14px;
}
</style>




