<template>
  <div class="login-panel">
    <login-form v-if='isLogin'></login-form>
    <forgot-password-form v-else></forgot-password-form>
    <login-bgc></login-bgc>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'
import LoginBgc from './login-bgc'
import LoginForm from './login.form'
import ForgotPasswordForm from './forgot-password/forgot-password-form'
export default defineComponent({
  props: {
     isLogin:{
      type:Boolean,
      default:true
    }
  },
  components: {
    LoginBgc,
    LoginForm,
    ForgotPasswordForm

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.login-panel {
  width: 760px;
  height: 450px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  div {
    flex: 1;
  }
}
</style>




