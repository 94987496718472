<template>
  <div class="forgot-password-form">
    <login-logo></login-logo>
    <el-form ref="ruleFormRef" :model="form" :rules="forgotPasswordFormRules" class="demo-ruleForm">
      <el-form-item prop="phone">
        <el-input v-model.number="form.phone" placeholder="手机号" />
      </el-form-item>

      <el-form-item class="code" prop="code">
        <el-input v-model="form.code" placeholder="验证码" maxlength='6'>
          <template #suffix>
            <el-button :disabled="codeButtonForm.state" @click="handleGetCode" class="code-btn" type="info">{{
                codeButtonForm.buttonValue
            }}</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="newPassword">
        <el-input v-model="form.newPassword" placeholder="新密码" />
      </el-form-item>

      <el-form-item prop="confirmPassword">
        <el-input v-model="form.confirmPassword" placeholder="重复新密码" />
      </el-form-item>

      <div class="operate">
        <span>已有账号</span>
        <span @click="handleGoLoginClick">返回登录</span>
      </div>
      <el-button v-if="isLogin" class="login-btn" @click="handleResetPasswordClick" type="primary">重置密码</el-button>

    </el-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { forgotPasswordFormRules } from '../../config/form-rules.js'
import { useRouter } from 'vue-router'
import LoginLogo from '../login-logo'
import { getPhoneCode,resetPassword } from '@/service/login'
import { ElMessage } from 'element-plus'
import {
  debounce
} from 'lodash'

export default defineComponent({
  props: {
    isLogin: {
      type: Boolean,
      default: true
    }
  },
  components: {
    LoginLogo
  },
  setup() {
    const router = useRouter();
    const ruleFormRef = ref(null)
    const form = reactive({
      phone: '',
      newPassword: '',
      confirmPassword: '',
      code: ''
    })

    const codeButtonForm = reactive({
      state: false,
      buttonValue: '获取验证码'
    })

    const handleResetPasswordClick = () => {
      ruleFormRef.value.validate(async(valid) => {
        if (valid) {
          if (form.newPassword!==form.confirmPassword) {
            ElMessage.error("两次密码不一致！")
            return
          }
          let params = {
            phone:form.phone,
            authCode:form.code,
            password:form.newPassword
          }
          let res = await resetPassword(params)
          if (res.code==0) {
            ElMessage.success('密码重置成功，请重新登录')
            handleGoLoginClick()
          }else{
            ElMessage.error(res.msg)
          }
        } else {
          return false
        }
      })
    }

    const handleGoLoginClick = () => {
      router.push('/login')
    }

    let timer = null
    const handleGetCode = debounce(async () => {
      if (!form.phone) {
        ElMessage.error("请先输入手机号")
        return
      }
      if (!(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(form.phone))) {
        ElMessage.error("手机号有误，请重新输入")
        return
      }
      await getPhoneCode({ phone: form.phone })
      let time = 60
      codeButtonForm.state = true
      timer = setInterval(() => {
        time--
        codeButtonForm.buttonValue = `${time}秒后可再次获取验证码`
        if (time < 1) {
          codeButtonForm.state = false
          clearInterval(timer)
          timer = null
          codeButtonForm.buttonValue = `获取验证码`
        }
      }, 1000);
    }, 1000)

    return {
      codeButtonForm,
      handleGetCode,
      form,
      handleResetPasswordClick,
      forgotPasswordFormRules,
      ruleFormRef,
      handleGoLoginClick
    }
  }
})
</script>

<style scoped lang="less">
.forgot-password-form {
  padding: 36px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .code {
    &:deep(.el-input--suffix) {
      box-sizing: border-box;
      height: 80%;

      .el-input__suffix {
        top: 4px;
        bottom: 4px;

        .el-button {
          min-height: 28px !important;
          padding: 0 6px !important;
          font-size: 12px !important;
        }
      }
    }
  }

  .login-btn {
    width: 100%;
    margin-top: 16px;
    height: 48px;
  }

  .operate {
    text-align: right;
    font-size: 13px;
    cursor: pointer;

    span:nth-child(1) {
      color: rgba(80, 80, 80, 1);
      margin-right: 4px;
    }

    span:nth-child(2) {
      color: rgba(42, 130, 228, 1);
    }
  }
}
</style>




