// 编写好规则
export const rules = {
  username: [
    {
      required: true,
      message: '用户名是必传内容~',
      trigger: 'blur'
    },
    {
      pattern: /^[a-z0-9]{5,11}$/,
      message: '用户名必须是5~10个字母或者数字~',
      trigger: 'blur'
    }
  ],
  password: [
    {
      required: true,
      message: '密码是必传内容~',
      trigger: 'blur'
    }
    // {
    //   pattern: /^[a-z0-9]{3,}$/,
    //   message: '用户名必须是3位以上的字母或者数字~',
    //   trigger: 'blur'
    // }
  ],
  // captcha: [
  //   {
  //     required: true,
  //     message: '请输入验证码',
  //     trigger: 'blur'
  //   }
  // ]
}

export const forgotPasswordFormRules = {
  phone: [
    {
      required: true,
      message: '手机号是必传内容~',
      trigger: 'blur'
    },
    {
      pattern:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: '请输入正确的手机号',
      trigger: 'blur'
    }
  ],
  newPassword: [
    {
      required: true,
      message: '密码是必传内容~',
      trigger: 'blur'
    },
    {
      pattern: /^[a-z0-9]{6,}$/,
      message: '用户名必须是6位以上的字母或者数字~',
      trigger: 'blur'
    }
  ],
  confirmPassword: [
    {
      required: true,
      message: '密码是必传内容~',
      trigger: 'blur'
    },
    {
      pattern: /^[a-z0-9]{6,}$/,
      message: '用户名必须是6位以上的字母或者数字~',
      trigger: 'blur'
    }
  ],
  code: [
    {
      required: true,
      message: '请输入验证码',
      trigger: 'blur'
    }
  ]
}
