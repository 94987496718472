<template>
  <div class="login-bgc">
       <img src="~assets/img/login/login-bgc.jpeg" alt="">
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
</style>




